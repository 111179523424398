<template>
  <div>
    <UForm :state="search" :class="xclass">
      <div class="flex w-full gap-x-4">
        <UInput
            v-model="search.query"
            :ui="{ icon: { trailing: { pointer: '' } } }"
            class="flex w-full"
            icon="i-ri-search-line"
            placeholder="Szukaj..."
            size="md"
        >
          <template #trailing>
            <UButton
                v-show="search.query !== ''"
                :padded="false"
                color="gray"
                icon="i-ri-close-line"
                variant="link"
                aria-label="wyczyść"
                @click="search.query = ''"
            />
          </template>
        </UInput>
        <UButton aria-label="Filtry" class="flex lg:hidden" :padded="false" color="gray" icon="i-ri-equalizer-line"
                 square variant="ghost" @click="emit('showFilterOverlay')"/>
      </div>
    </UForm>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  xclass: {
    type: String,
    required: false,
    default: 'flex w-full',
  },
});

const emit = defineEmits(['showFilterOverlay']);

const router = useRouter();
const route = useRoute();


const search = ref({
  query: route.query['filter[query]'] ?? '',
});

watch(search.value, (s) => {
  if (s.query.length && s.query.length < 3) {
    return;
  }

  router.push({
    path: '/',
    query: s.query.length ? {'filter[query]': s.query} : undefined,
  })
});
</script>
